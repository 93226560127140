const externalLinks = {
  serviceStatus: () => 'https://status.rapidcompact.com/',
  onlineDocumentation: () => 'https://docs.rapidpipeline.com',
  changelog: () => 'https://docs.rapidpipeline.com/rapidpipeline-platform-updates',
  cliDoc: () => 'https://rapidcompact.com/doc/cli/index.html',
  cliSetup: () => 'https://rapidcompact.com/doc/shared/RapidCompact-CLI-setup-guide.pdf',
  supportHome: () => 'https://dgg3d.freshdesk.com/support/home',
  legacyFrontend: () => 'https://legacy.rapidcompact.com',
  assetGuidelines: () =>
    'https://docs.rapidcompact.com/docs/rapidpipeline-cloud-tutorials/getting-started#uploading-your-models',
};

export default externalLinks;
