import {
  completeUpload,
  createRawModel,
  finishAssetUpload,
  getPartUploadUrl,
  startUpload,
} from '@/app/assets/actions/rawModelUpload';
import axios from 'axios';
import { getFileExtension, mainTypes, nameWithoutExt } from '@/utils/fileUtils';
import { useEffect, useState } from 'react';
import { FileWithPath } from '@mantine/dropzone';
import { useThrottledCallback } from 'use-debounce';
import { ModelImportConfig } from '@/app/assets/components/upload/hooks/usePBROptions';
import { RawModel } from '@/types/rawModel';

export type RawAssetsUploadProgress = {
  filesFinished: number;
  totalFiles: number;
  filesProgress: Record<string, number>;
  totalProgress: number;
};

export const useRawAssetUpload = (
  files: FileWithPath[],
  onAssetsUploading: (model: RawModel[]) => void
) => {
  const [uploadProgress, setUploadProgress] = useState<RawAssetsUploadProgress>(
    {
      filesFinished: 0,
      totalFiles: 0,
      filesProgress: {},
      totalProgress: 0,
    }
  );

  const debouncedFileProgressUpdate = useThrottledCallback(
    (value: (prev: RawAssetsUploadProgress) => RawAssetsUploadProgress) =>
      setUploadProgress(value),
    300
  );

  useEffect(() => {
    setUploadProgress({
      filesFinished: 0,
      totalFiles: files.length,
      filesProgress: {},
      totalProgress: 0,
    });
  }, [files.length]);

  const uploadFiles = async (modelImportConfig?: ModelImportConfig) => {
    const mainFiles = files.filter((file) =>
      mainTypes.includes(getFileExtension(file).toLowerCase())
    );

    if (mainFiles.length === 1) {
      return uploadMultiFileAsset(
        mainFiles[0],
        files,
        debouncedFileProgressUpdate,
        onAssetsUploading,
        modelImportConfig
      );
    }

    return uploadMultipleAssets(
      files,
      debouncedFileProgressUpdate,
      onAssetsUploading,
      modelImportConfig
    );
  };

  return {
    uploadProgress,
    uploadFiles,
  };
};

async function uploadMultiFileAsset(
  mainFile: FileWithPath,
  files: FileWithPath[],
  setUploadProgress: (
    value: (prevState: RawAssetsUploadProgress) => RawAssetsUploadProgress
  ) => void,
  onAssetsUploading: (model: RawModel[]) => void,
  modelImportConfig?: ModelImportConfig
) {
  const rawModel = await createRawModel({
    model_name: nameWithoutExt(mainFile.name),
    model_size: mainFile.size,
    model_import_config: modelImportConfig,
  });

  const assets = mapFilesToAsset([mainFile], rawModel.id);
  onAssetsUploading(assets);

  await Promise.all(
    files.map(async (file) => {
      const startUploadResult = await startUpload({
        uuid: rawModel.uuid,
        filename: file.name,
      });

      const response = await getPartUploadUrl({
        key: startUploadResult.key,
        part_number: 1,
        upload_id: startUploadResult.upload_id,
        content_length: file.size,
      });

      await axios.put(response.url, file, {
        onUploadProgress: (progressEvent) => {
          setUploadProgress((s) => {
            const newProgress = {
              ...s.filesProgress,
              [file.name]: progressEvent.progress ?? 0,
            };

            const totalProgress =
              Object.values(newProgress).reduce((acc, next) => acc + next, 0) /
              files.length;

            return {
              filesFinished:
                progressEvent.progress === 1
                  ? s.filesFinished + 1
                  : s.filesFinished,
              totalFiles: files.length,
              filesProgress: {
                ...s.filesProgress,
                [file.name]: progressEvent.progress ?? 0,
              },
              totalProgress,
            };
          });
        },
      });

      await completeUpload({
        upload_id: startUploadResult.upload_id,
        key: startUploadResult.key,
      });
    })
  );

  await finishAssetUpload({ rawModelId: rawModel.id });
}

const uploadMultipleAssets = async (
  files: FileWithPath[],
  setUploadProgress: (
    value: (prevState: RawAssetsUploadProgress) => RawAssetsUploadProgress
  ) => void,
  onAssetsUploading: (model: RawModel[]) => void,
  modelImportConfig?: ModelImportConfig
) => {
  await Promise.all(
    files.map(async (file) => {
      const rawModel = await createRawModel({
        model_name: nameWithoutExt(file.name),
        model_size: file.size,
        model_import_config: modelImportConfig,
      });

      const assets = mapFilesToAsset([file], rawModel.id);
      onAssetsUploading(assets);

      const startUploadResult = await startUpload({
        uuid: rawModel.uuid,
        filename: file.name,
      });

      const response = await getPartUploadUrl({
        key: startUploadResult.key,
        part_number: 1,
        upload_id: startUploadResult.upload_id,
        content_length: file.size,
      });

      await axios.put(response.url, file, {
        onUploadProgress: (progressEvent) => {
          setUploadProgress((s) => {
            const newProgress = {
              ...s.filesProgress,
              [file.name]: progressEvent.progress ?? 0,
            };

            const totalProgress =
              Object.values(newProgress).reduce((acc, next) => acc + next, 0) /
              files.length;

            return {
              filesFinished:
                progressEvent.progress === 1
                  ? s.filesFinished + 1
                  : s.filesFinished,
              totalFiles: files.length,
              filesProgress: {
                ...s.filesProgress,
                [file.name]: progressEvent.progress ?? 0,
              },
              totalProgress,
            };
          });
        },
      });

      await completeUpload({
        upload_id: startUploadResult.upload_id,
        key: startUploadResult.key,
      });

      await finishAssetUpload({ rawModelId: rawModel.id });
    })
  );
};

function mapFilesToAsset(files: FileWithPath[], id: number): RawModel[] {
  return files.map((f) => ({
    upload_status: 'client uploading',
    format: getFileExtension(f),
    name: f.name.replace(`.${getFileExtension(f)}`, ''),
    size: f.size,
    rapidModels: [],
    id,
    created_at: new Date().toISOString(),
    rpdInfo: { polygons: null },
    assetType: 'raw',
    scale_factor: 1,
    warnings: [],
    errors: [],
  }));
}
