import {
  getOsInfo,
  getUserInfo,
  parseModelsForSupport,
} from '@/app/components/supportTicket/utils';
import { createSupportTicket } from '@/app/components/supportTicket/actions/createSupportTicket';
import {
  showErrorNotification,
  showSuccessNotification,
} from '@/app/components/shared/notifications';
import { SupportRequestFormValues } from '@/app/components/supportTicket/SupportTicketModal';
import { useVersionsQuery } from '@/app/components/navbar/hooks/useVersionsQuery';
import { ApiUser } from '@/types/user';
import { useMutation } from '@tanstack/react-query';

export const useCreateSupportRequest = (user?: ApiUser) => {
  const { data: versions } = useVersionsQuery();

  const { mutateAsync: sendRequest, isPending } = useMutation({
    mutationFn: async (values: SupportRequestFormValues) => {
      if (!user) {
        throw new Error('User not found');
      }
      const description =
        values.message +
        parseModelsForSupport(values.models) +
        getOsInfo(versions?.rpdxVersions, versions?.appVersion) +
        getUserInfo(user);
      const subject = values.subject;

      await createSupportTicket(subject, description);
    },
    onSuccess: () => {
      showSuccessNotification({
        title: 'Support Ticket',
        message: 'Support Ticket created successfully.',
      });
    },
    onError: () => {
      showErrorNotification({
        title: 'Support Ticket',
        message: 'There was an error creating support ticket.',
      });
    },
  });

  return {
    sendRequest,
    isPending,
  };
};
