import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useFiles } from '@/app/assets/components/upload/hooks/useFiles';
import { RawModel } from '@/types/rawModel';
import { showSuccessNotification } from '@/app/components/shared/notifications';

type AssetsUploadContextValue = ReturnType<typeof useFiles> | undefined;

const AssetsUploadContext = createContext<AssetsUploadContextValue>(undefined);

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
};

export const AssetsUploadContextProvider = ({
  children,
}: PropsWithChildren) => {
  const onFilesUploaded = () =>
    showSuccessNotification({
      title: 'Assets upload',
      message: 'Assets have been successfully uploaded.',
    });

  const {
    files,
    isUploadPending,
    filesPendingUpload,
    removeFile,
    removeAllFiles,
    uploadFiles,
    uploadProgress,
    onFileDropped,
  } = useFiles(onFilesUploaded);

  useEffect(() => {
    if (!filesPendingUpload.length) {
      return;
    }
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [filesPendingUpload.length]);

  return (
    <AssetsUploadContext.Provider
      value={{
        isUploadPending,
        files,
        filesPendingUpload,
        removeFile,
        removeAllFiles,
        uploadFiles,
        uploadProgress,
        onFileDropped,
      }}
    >
      {children}
    </AssetsUploadContext.Provider>
  );
};

export const useAssetsUploadContext = (
  onFilesBeingUploaded: (files: RawModel[]) => void,
  onFilesUploaded: (files: RawModel[]) => void
) => {
  const context = useContext(AssetsUploadContext);

  if (context === undefined) {
    throw new Error(
      'useAssetsUploadContext must be within AssetsUploadContextProvider'
    );
  }

  const previousFiles = useRef(context.filesPendingUpload);

  useEffect(() => {
    const uploadedFiles: RawModel[] = previousFiles.current
      .filter((i) => !context.filesPendingUpload.some((f) => f.id === i.id))
      .map((f) => ({ ...f, upload_status: 'just uploaded' }));
    if (uploadedFiles.length) {
      onFilesUploaded(uploadedFiles);
    }
    previousFiles.current = context.filesPendingUpload;
    if (!context.filesPendingUpload.length) {
      return;
    }
    onFilesBeingUploaded(context.filesPendingUpload);
  }, [context.filesPendingUpload, onFilesBeingUploaded, onFilesUploaded]);

  return context;
};
